import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { ElMessage } from 'element-plus';
export default {
  components: {},
  data: function data() {
    return {
      userInfo: {
        givenName: '',
        phoneNumber: ''
      },
      loading: false,
      type: null,
      title: null,
      content: null,
      time: null,
      admin: null,
      viewData: {
        category: {},
        thumbnail: {}
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.type = this.$route.query.type, this.title = this.$route.query.title, this.content = this.$route.query.content, this.time = this.$route.query.time, this.admin = "admin", console.log(this.$route.query.id); // 设置userinfo

    var userInfo = JSON.parse(window.localStorage.getItem('userInfo'));

    if (userInfo && userInfo !== 'null') {
      this.userInfo = userInfo;
    }
  },
  methods: {
    goHome: function goHome() {
      debugger;
      this.$router.push('home');
    },
    // 退出登录
    logout: function logout() {
      window.localStorage.clear();
      this.$router.replace('home');
    }
  }
};